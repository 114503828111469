<template>
  <v-data-table
    id="transactions-table-transactions_table"
    :footer-props="{'items-per-page-options':[5, 10, 15, 50],'show-first-last-page':true,'show-current-page':true}"
    :headers="viewType === 'sent' ? sentHeaders : scheduledHeaders"
    :items="availableTransactions"
    :loading="loading"
    :options.sync="internalFrontOptions"
    :server-items-length="totalRecords"
    calculate-widths
    class="elevation-0 col-12 px-0 pt-0 mt-0 cts-finder-table"
    dense
    @update:options="handleOptionsUpdate"
  >
    <template v-slot:item="{item}">
      <tr v-if="$vuetify.breakpoint.mdAndUp" class="cts-datatable-row">
        <td>
          <v-list-item class="px-0">
            <v-list-item-avatar id="transactions-table-transaction_row_icon" class="cts-process-card-icon ma-1"
                                size="35" tile>
              <icon-base
                :icon-color="viewType==='sent'? 'var(--primary-alternative)' :'var(--darken)'"
                :icon-name="item.translated_process_name"
                :src="item.process_image"
                height="28"
                width="28"
              />
            </v-list-item-avatar>

            <v-list-item-content :title="item.instance_code" class="pl-2" style="max-width: 400px">
              <a v-if="viewType === 'sent'" class="font-weight-bold d-block cts-color-primary-alternative text-ellipsis"
                 @click="openTransaction(item.instance_code)">
                {{item.transaction_name && item.transaction_name !== item.process_name ? item.transaction_name : item.translated_process_name}}
              </a>

              <span v-else class="font-weight-bold d-block text-truncate">
                {{item.transaction_name && item.transaction_name !== item.process_name ? item.transaction_name : item.translated_process_name}}
              </span>

              <span class="cts-color-tertiary text-uppercase text-truncate">
                {{item.instance_code}}
              </span>
            </v-list-item-content>
          </v-list-item>
        </td>
        <td>
          <span class="font-weight-medium d-block">
            {{dayjs.unix(viewType === 'sent' ? item.creation_timestamp : item.schedule_time).format(const_global.DATE_TIME_FORMAT_A)}}
          </span>
          <span v-if="viewType === 'sent'" class="cts-color-tertiary">
            {{dayjs.unix(item.creation_timestamp).locale(currentLocation.code).fromNow()}}
          </span>
          <span v-else class="cts-color-tertiary">
            {{item.schedule_time - dayjs().unix() < 0 ? $vuetify.lang.t('$vuetify.nextMinutes') : dayjs.unix(item.schedule_time).locale(currentLocation.code).fromNow()}}
          </span>
        </td>
        <td v-if="viewType === 'sent'" style="max-width: 200px">
          <v-col class="py-1">
            <v-row dense>
              <v-col class="pa-0">
                <v-row
                  v-for="(intervener, index) in item.showAllInterveners ? item.interveners : item.interveners.slice(0, 1)"
                  :key="index"
                  class="transaction-interveners-row"
                  dense
                >
                  <span
                    :title="intervener.personal_info.name +  getIntervenerId(intervener)"
                    class="font-weight-medium cts-color-tertiary text-truncate">
                    {{intervener.personal_info.name}}
                    <span class="font-weight-regular">
                    {{getIntervenerId(intervener)}}
                    </span>

                  </span>


                </v-row>
                <v-row v-if="item.interveners.length > 1"
                       dense>
                  <v-btn
                    class=" more-interveners-button"
                    small
                    text
                    @click="item.showAllInterveners ? item.showAllInterveners = false : item.showAllInterveners = true"
                  >
                    <v-icon
                      class="ma-0" style="color: var(--primary-alternative)">
                      {{item.showAllInterveners ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                    </v-icon>
                    <span
                      style="color: var(--primary-alternative)">
                      {{item.showAllInterveners ? $vuetify.lang.t('$vuetify.hide') : item.interveners.length - 1 + ' ' + $vuetify.lang.t('$vuetify.more')}}
                    </span>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </td>
        <td v-if="viewType === 'sent'" class="text-start cts-transaction-datatable-state">
          <h4 v-if="item.instance_code.indexOf('INVALID') !== -1" class=" font-weight-medium"
              style="color: var(--error);">Transacción no válida</h4>
          <span v-else>
                <span style="font-size: 12px; color: var(--tertiary)">
                    {{dayjs.unix(item.macrostatus_timestamp !== 0 ? item.macrostatus_timestamp : item.creation_timestamp).format(const_global.DATE_TIME_FORMAT_A)}}</span>
                <h4 id="transactions-table-transaction_state"
                    class="cts-color-tertiary cts-break-word font-weight-medium">{{item.macrostatus.charAt(0).toUpperCase() + item.macrostatus.slice(1)}}</h4>
            </span>
        </td>
        <td v-if="viewType === 'scheduled'">
          <span>{{item.source_extra.username}}</span>
        </td>
        <td class="text-center">
          <download-certificate
            v-if="item.certificate_key !== '0' && checkPermission(const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE) &&viewType === 'sent'"
            id="transactions-table-transaction_download_certificate"
            :buttonDisabled="item.certificate_key !== '0'" :hash="item.certificate_key"
            class="d-block mx-auto"
            mode="icon"
          />
          <v-tooltip v-else-if="viewType === 'sent'" bottom>
            <template v-slot:activator="{ on, attrs }">
          <span>
            <v-icon
              class="d-block mx-auto"
              color="var(--input-icons)"
              style="cursor: default"
              v-bind="attrs"
              v-on="on"
            >
              {{const_icons.DOWNLOAD_DOCUMENT}}
            </v-icon>
          </span>
            </template>
            <span>{{$vuetify.lang.t('$vuetify.withoutCertificated')}}</span>
          </v-tooltip>
          <v-btn
            v-if="viewType === 'scheduled'"
            id="transaction-button-cancel_transaction"
            :disabled="!availableCancelTransaction(item.schedule_time)"
            :title="$vuetify.lang.t('$vuetify.cancelTransaction')"
            class="cts-actions-button"
            text
            @click="$emit('cancelTransaction',item.instance_code)">
            <v-icon left size="24px">
              cancel
            </v-icon>
          </v-btn>
        </td>
      </tr>
      <v-card v-else :link="viewType==='sent'" :style="viewType === 'scheduled' ? 'width: 95%' : ''" class="my-2"
              outlined
              @click="viewType === 'sent' ? openTransaction(item.instance_code) : null">
        <v-list-item class="px-0">

          <v-list-item-content class="pl-2">
            <a v-if="viewType === 'sent'" class="font-weight-bold d-block cts-color-primary-alternative">
              {{item.transaction_name && item.transaction_name !== item.process_name ? item.transaction_name : item.translated_process_name}}
            </a>
            <span v-else class="font-weight-bold d-block">
                {{item.transaction_name && item.transaction_name !== item.process_name ? item.transaction_name : item.translated_process_name}}
              </span>
            <span class="cts-color-tertiary text-uppercase" style="">{{item.instance_code}}</span>
            <v-divider class="mt-1"/>
            <span class=" d-block py-1">
              <span class="font-weight-medium">{{$vuetify.lang.t('$vuetify.creation')}}:</span>
              {{dayjs.unix(viewType === 'sent' ? item.creation_timestamp : item.schedule_time).format(const_global.DATE_TIME_FORMAT_A)}} - {{dayjs.unix(viewType === 'sent' ? item.creation_timestamp : item.schedule_time).locale(currentLocation.code).fromNow()}}
            </span>
            <span v-if="viewType === 'sent'" class="py-1">
              <span class="font-weight-medium">
                {{$vuetify.lang.t('$vuetify.interveners')}} ({{item.interveners.length}}):
              </span>
              <span v-for="(intervener, index) in item.interveners" :key="index" class="d-inline">
                {{intervener.personal_info.name}}
                    {{getIntervenerId(intervener)}}
                {{index === 0 && item.interveners.length > 1 ? '| ' : ''}}
              </span>
            </span>
            <span v-if="viewType === 'sent'" class="py-1">
              <span class="font-weight-medium">{{$vuetify.lang.t('$vuetify.state')}}: </span>
              <span v-if="item.instance_code.indexOf('INVALID') !== -1" class=" "
                    style="color: var(--error);">
                {{$vuetify.lang.t('$vuetify.invalidTransaction')}}
              </span>
              <span v-else>
                <span id="transactions-table-transaction_state"
                      class="cts-color-tertiary cts-break-word ">{{item.macrostatus.charAt(0).toUpperCase() + item.macrostatus.slice(1)}}</span>
              </span>
            </span>
            <span v-if="viewType === 'scheduled'">
              <span class="font-weight-medium">{{$vuetify.lang.t('$vuetify.shipmentDate')}}:</span>
              {{dayjs.unix(item.schedule_time).format(const_global.DATE_TIME_FORMAT_A)}}
            </span>
          </v-list-item-content>
          <v-list-item-action v-if="viewType === 'scheduled'" class="ma-0">
            <v-btn
              id="transaction-button-cancel_transaction"
              :disabled="!availableCancelTransaction(item.schedule_time)"
              :title="$vuetify.lang.t('$vuetify.cancelTransaction')"
              class="cts-actions-button"
              text
              @click="$emit('cancelTransaction',item.instance_code)">
              <v-icon left size="24px">
                cancel
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </template>
  </v-data-table>
</template>

<script lang="js">
import dayjs from "dayjs";
import const_global from "@/constants/global";
import const_permissions from "@/constants/permissions";
import const_icons from "@/constants/icons";
import IconBase from "@/assets/images/IconBase.vue";
import DownloadCertificate from "@/components/structures/downloadCertificate.vue";
import {checkPermission} from "@/util/store_utils";

export default {
  name: "TransactionsDatatable",
  components: {DownloadCertificate, IconBase},
  props: {
    availableTransactions: Array,
    frontOptions: Object,
    totalRecords: Number,
    currentLocation: Object,
    availableStatuses: Array,
    viewType: String,
    loading: Boolean
  },
  data() {
    return {
      dayjs,
      const_global,
      const_permissions,
      const_icons,
      sentHeaders: [
        {
          text: this.$vuetify.lang.t('$vuetify.process'),
          value: 'instance_code',
          width: '30%',
          sortable: false
        }, {
          text: this.$vuetify.lang.t('$vuetify.creation'),
          value: 'creation_timestamp',
          width: '20%',
          sortable: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.interveners'),
          value: 'interveners',
          width: '20%',
          sortable: false
        }, {
          text: this.$vuetify.lang.t('$vuetify.state'),
          value: 'macrostatus_timestamp',
          width: '30%',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions'),
          align: 'center',
          value: 'actions',
          sortable: false,
          custom: true,
          width: '100px'
        }
      ],
      scheduledHeaders: [
        {
          text: this.$vuetify.lang.t('$vuetify.process'),
          value: 'instance_code',
          width: '30%',
          sortable: false
        }, {
          text: this.$vuetify.lang.t('$vuetify.shipmentDate'),
          width: '30%',
          value: 'schedule_time',
          sortable: false
        }, {
          text: this.$vuetify.lang.t('$vuetify.usernameTransactionDetail'),
          width: '30%',
          value: 'source_extra',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions'),
          align: 'center',
          value: 'actions',
          sortable: false,
          custom: true,
          width: '100px'
        }
      ],
      internalFrontOptions: this.frontOptions
    }
  },
  methods: {
    checkPermission,
    handleOptionsUpdate() {
      this.$emit('updateOptions', this.internalFrontOptions)
    },
    openTransaction(instance_code) {
      this.$emit('openTransaction', instance_code)
    },
    downloadCertificate(instance_code) {
      this.$emit('downloadCertificate', instance_code)
    },
    availableCancelTransaction(schedule_time) {
      const now = dayjs()
      const schedule = dayjs.unix(schedule_time)
      return schedule.isAfter(now.add(5, 'minutes'))
    },
    getIntervenerId(intervener) {
      if (intervener.personal_info.identification_document && intervener.personal_info.identification_document.document_code) {
        return ` (${intervener.personal_info.identification_document.document_code})`
      }
      if (intervener.client_id) {
        return ` (${intervener.client_id})`
      }
      return ""
    }
  }
}
</script>

<style lang="scss">
.more-interveners-button {
  height: 21px !important;

  i {
    height: 13px;
  }

  span {
    font-size: 13px !important;
    text-transform: lowercase;
  }
}
</style>